import { BsFillBagDashFill } from "react-icons/bs";
import { CgWebsite } from "react-icons/cg";
import { FaFileInvoiceDollar, FaHome } from "react-icons/fa";
import { GiMoneyStack } from "react-icons/gi";
import { MdOutlineSupportAgent } from "react-icons/md";
import { PiStudentFill } from "react-icons/pi";
import { TbWorldCode } from "react-icons/tb";

export const sidebarRoutes = {
    user: [
      { path: "/", label: "Dashboard", icon: <FaHome /> },
      { path: "/job", label: "Job Post", icon: <BsFillBagDashFill /> },
    ],
    admin: [
      { path: "/", label: "Dashboard", icon: <FaHome /> },
      { path: "/job", label: "Job Post", icon: <BsFillBagDashFill /> },
      { path: "/enquiry", label: "Enquiry", icon: <MdOutlineSupportAgent /> },
      { path: "/salary-slip", label: "Salary Slip", icon: <FaFileInvoiceDollar /> },
      { path: "/student-admission", label: "Students Admission", icon: <PiStudentFill /> },
      { path: "/online-admission", label: "Online Admission", icon: <TbWorldCode /> },
      { path: "/fee-details", label: "Fee Details", icon: <GiMoneyStack /> },
      { path: "/project", label: "Project Enquiry", icon: <CgWebsite /> },
    ],
  };
  