import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import numberToWords from "number-to-words";
import logo from "../assets/mindcoderlogo.webp";
import sign from "../assets/stampSign.png";
import { formatCurrency } from "../utils/CurrencyFormat";

const SalarySlipModal = ({ isOpen, onClose, payslipData }) => {
  const [isLoading, setIsLoading] = useState(false);

  if (!isOpen) return null;

  const netSallaryInWords = numberToWords.toWords(payslipData.netSalary);

  // generate pdf
  const generatePDF = () => {
    setIsLoading(true); // Start the loader
    const modalContent = document.getElementById("modalContent");

    html2canvas(modalContent, {
      scale: 5,
      useCORS: true,
      imageTimeout: 5000,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // A4 size in pixels (at 96 DPI)
      const pdfWidth = 595.28;
      const pdfHeight = 841.89;
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: "a4",
      });

      // Calculate the scaling factor to fit the content to A4 size
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const width = imgWidth * ratio;
      const height = imgHeight * ratio;

      pdf.addImage(imgData, "PNG", 0, 0, width, height);
      console.log(canvas.height);
      console.log(canvas.width);

      pdf.save("payslip.pdf");
      setIsLoading(false); // Stop loading
    });
  };

  return (
    <div className="fixed z-50 flex pt-[600px] inset-0 items-center overflow-y-scroll justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg">
        <div className="w-[60vw]">
          <div className="mt-4" id="modalContent">
            <div className="max-w-2xlmx-auto py-6 px-6 bg-white">
              <header className="flex items-center gap-2">
                <img
                  className="w-[150px] ml-3"
                  // src="https://www.mindcoders.in/assets/img/mindcoderlogo.webp"
                  src={logo}
                  alt="logo"
                />
                <div className="bg-gray-300 h-[80px] w-[3px] text-gray-300 rounded-xl">
                  |
                </div>
                <div className="pb-5">
                  <h1 className="text-2xl font-bold">Mind Coders</h1>
                  <p>
                    206, Pearl Business Park, Near Bhawarkuwa, <br /> Indore
                    452010 India
                  </p>
                </div>
                <div className="flex items-end ml-20 flex-col">
                  <h2 className="text-lg font-semibold">
                    Payslip For the Month
                  </h2>
                  <p className="font-semibold">
                    {payslipData.formattedPayPeriod}
                  </p>
                </div>
              </header>
              <hr className="mx-5 my-4 " />
              <section className="">
                <h2 className="text-xl mx-5 text-gray-500 font-bold mb-2">
                  EMPLOYEE SUMMARY
                </h2>
                <div className="flex justify-between mx-5 gap-4">
                  <div className="flex justify-between gap-5">
                    <div className="text-gray-500 font-semibold">
                      <p>Employee Name</p>
                      <p>Employee ID</p>
                      <p>Pay Period</p>
                      <p>Pay Date</p>
                    </div>
                    <div>
                      <p className="font-semibold capitalize">
                        {" "}
                        <strong>:</strong> {payslipData.employeeName}
                      </p>
                      <p className="font-semibold uppercase">
                        {" "}
                        <strong>:</strong> MIND{payslipData.employeeId}
                      </p>
                      <p className="font-semibold">
                        {" "}
                        <strong>:</strong> {payslipData.formattedPayPeriod}
                      </p>
                      <p className="font-semibold">
                        {" "}
                        <strong>:</strong>{" "}
                        {new Date(payslipData.payDate).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="w-60">
                    <div className="border divide-y-2 divide-dashed border-gray-300 rounded-lg">
                      <div className="px-4 pb-2 pt-4 rounded-t-lg bg-green-100">
                        <div className="flex gap-2">
                          <div className="h-18 rounded-xl text-green-500 bg-green-500">
                            |
                          </div>
                          <div className="pb-5">
                            <p className="text-xl font-bold">
                              {formatCurrency(payslipData.netSalary)}/-
                            </p>
                            <p className="text-lg text-gray-500">
                              Employee Net Pay
                            </p>
                          </div>
                        </div>
                        {/* <hr className="m-2" /> */}
                      </div>

                      <div className="flex gap-5 pb-4 px-4">
                        <div>
                          <p className="text-gray-500 font-semibold">
                            Paid Days
                          </p>
                          <p className="text-gray-500 font-semibold">
                            Days LOP
                          </p>
                        </div>
                        <div>
                          <p>
                            <strong>:&nbsp;&nbsp;&nbsp;</strong>
                            {payslipData.paidDays}
                          </p>
                          <p>
                            <strong>:&nbsp;&nbsp;&nbsp;</strong>
                            {payslipData.lossOfPayDays}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="mb-6 mt-6">
                <div className="border border-gray-300 rounded-lg">
                  <table className="rounded-lg w-full bg-white">
                    <tbody className="text-start">
                      <tr className="border-b border-dashed">
                        <td className="pb-5 px-4 font-bold">EARNINGS</td>
                        <td className="pb-5 px-4 font-bold">AMOUNT</td>
                        <td className="pb-5 px-4 font-bold">DEDUCTIONS</td>
                        <td className="pb-5 px-4 font-bold">AMOUNT</td>
                      </tr>
                    </tbody>
                    <tbody className="text-start">
                      <tr>
                        <td className="py-2 px-4">Basic</td>
                        <td className="py-2 px-4 font-semibold">
                          {formatCurrency(payslipData.basicSalary)}
                        </td>
                        <td className="py-2 px-4">Income Tax</td>
                        <td className="py-2 px-4 font-semibold">
                          {formatCurrency(payslipData.incomeTax)}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-4">House Rent Allowance</td>
                        <td className="py-2 px-4 font-semibold">
                          {formatCurrency(payslipData.hra)}
                        </td>
                        <td className="py-2 px-4">Provident Fund</td>
                        <td className="py-2 px-4 font-semibold">
                          {formatCurrency(payslipData.providentFund)}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-4">Medical Allowance</td>
                        <td className="py-2 px-4 font-semibold">
                          {formatCurrency(payslipData.medical)}
                        </td>
                        <td className="py-2 px-4">Loss of Pay</td>
                        <td className="py-2 px-4 font-semibold">
                          {formatCurrency(payslipData.payLoss)}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-4">Attendance Allowance</td>
                        <td className="py-2 px-4 font-semibold">
                          {formatCurrency(payslipData.attendanceAllowance)}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-4">Transport Allowance</td>
                        <td className="py-2 px-4 font-semibold">
                          {formatCurrency(payslipData.transportAllowance)}
                        </td>
                      </tr>
                      <tr>
                        <td className="pb-4 px-4">
                          PLI<strong>*</strong>
                        </td>
                        <td className="pb-4 px-4 font-semibold">
                          {formatCurrency(payslipData.pli)}
                        </td>
                      </tr>
                      <tr className="bg-gray-100 rounded-b-lg">
                        <td className="pb-5 px-4 border-t border-dashed font-bold">
                          Gross Earnings
                        </td>
                        <td className="pb-5 px-4 border-t border-dashed font-bold">
                          {formatCurrency(payslipData.grossSalary)}/-
                        </td>
                        <td className="pb-5 px-4 border-t border-dashed font-bold">
                          Total Deductions
                        </td>
                        <td className="pb-5 border-t border-dashed font-bold">
                          {formatCurrency(payslipData.totalDeduction)}/-
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
              <section className="border-2 h-[60px] flex justify-between items-center rounded-lg w-full">
                <div className="px-4 pb-4">
                  <p className="font-bold">TOTAL NET PAYABLE</p>
                  <p className="text-sm">Gross Earnings - Total Deductions</p>
                </div>

                <div className="bg-green-100 h-full font-bold rounded-e-md">
                  <p className="p-2">
                    {" "}
                    {formatCurrency(payslipData.netSalary)}/-
                  </p>
                </div>
              </section>
              <footer>
                <p className="text-gray-500 mt-5 text-end">
                  Amount In Words{" "}
                  <strong className="capitalize">
                    : {netSallaryInWords} only/-
                  </strong>
                </p>
                <div className="flex flex-col items-end justify-end">
                  <img src={sign} alt="Director Signature" className="w-48 h-48" />
                  <p className="relative bottom-16 right-14">Director</p>
                </div>
              </footer>
            </div>
          </div>
          <div className="flex justify-center items-center gap-10 pb-10 mt-5">
            {isLoading ? (
              <button disabled type="button">
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline mr-3 w-4 h-4 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  ></path>
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  ></path>
                </svg>
                Loading...
              </button>
            ) : (
              <button className="text-sm" onClick={generatePDF}>
                Generate PDF
              </button>
            )}
            <div
              className="text-sm border-2 font-semibold h-[40px] border-red-600 rounded-md w-[100px] cursor-pointer flex justify-center items-center hover:scale-105 transition-all"
              onClick={onClose}
            >
              Close
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalarySlipModal;
