// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@supports not selector(::-webkit-scrollbar) {
  body {
    scrollbar-color: var(--sb-thumb-color)
                     var(--sb-track-color);
  }
}
.input {
  /* width: 300px;
  max-width: 220px; */
  height: 45px;
  padding: 12px;
  border-radius: 12px;
  border: 1.5px solid lightgrey;
  outline: none;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0px 0px 20px -18px;
}

.input:hover {
  border: 2px solid #0FCFFF;
  box-shadow: 0px 0px 20px -17px;
}

.input:active {
  transform: scale(0.95);
}

.input:focus {
  border: 2px solid #05314B;
}

button {
  text-decoration: none;
  margin-top:8px ;
  position: relative;
  border: none;
  font-size: 16px;
  font-family: inherit;
  cursor: pointer;
  color: #fff;
  width: 9em;
  height: 3em;
  line-height: 2em;
  text-align: center;
  background: #001e72;
  background-size: 300%;
  border-radius: 5px;
  z-index: 1;
  transition: all 0.3s ease;
}

button:hover {
  border: none;
  scale: 1.1;
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0; 
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE;IACE;0CACsC;EACxC;AACF;AACA;EACE;qBACmB;EACnB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,aAAa;EACb,mDAAmD;EACnD,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,oBAAoB;EACpB,eAAe;EACf,WAAW;EACX,UAAU;EACV,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,qBAAqB;EACrB,kBAAkB;EAClB,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,eAAe;AACjB;;AAEA;;IAII,gBAAgB;IAChB,SAAS;AACb","sourcesContent":["\n@supports not selector(::-webkit-scrollbar) {\n  body {\n    scrollbar-color: var(--sb-thumb-color)\n                     var(--sb-track-color);\n  }\n}\n.input {\n  /* width: 300px;\n  max-width: 220px; */\n  height: 45px;\n  padding: 12px;\n  border-radius: 12px;\n  border: 1.5px solid lightgrey;\n  outline: none;\n  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);\n  box-shadow: 0px 0px 20px -18px;\n}\n\n.input:hover {\n  border: 2px solid #0FCFFF;\n  box-shadow: 0px 0px 20px -17px;\n}\n\n.input:active {\n  transform: scale(0.95);\n}\n\n.input:focus {\n  border: 2px solid #05314B;\n}\n\nbutton {\n  text-decoration: none;\n  margin-top:8px ;\n  position: relative;\n  border: none;\n  font-size: 16px;\n  font-family: inherit;\n  cursor: pointer;\n  color: #fff;\n  width: 9em;\n  height: 3em;\n  line-height: 2em;\n  text-align: center;\n  background: #001e72;\n  background-size: 300%;\n  border-radius: 5px;\n  z-index: 1;\n  transition: all 0.3s ease;\n}\n\nbutton:hover {\n  border: none;\n  scale: 1.1;\n  cursor: pointer;\n}\n\ninput[type=number]::-webkit-inner-spin-button, \ninput[type=number]::-webkit-outer-spin-button { \n    -webkit-appearance: none;\n    -moz-appearance: none;\n    appearance: none;\n    margin: 0; \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
