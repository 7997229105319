import React from 'react'
import OnlineAdmissionTable from '../components/OnlineAdmissions'

const OnlineAdmission = () => {
  return (
    <div className="flex flex-col w-full h-[100vh]">
      <div className="bg-blue-800 flex items-center justify-center  text-white font-bold text-center text-2xl py-4">
        <h1 className="w-fit">Students Admission</h1>
      </div>
      <section className="overflow-y-scroll">
        <OnlineAdmissionTable/>
      </section>

    </div>
  )
}

export default OnlineAdmission;