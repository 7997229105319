import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import axiosInstance from "../axiosInstance/axiosInstance";
import loginImg from "../assets/loginImg.jpg";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();

  // Handle Login
  const handleLogin = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (!email || !password) {
      toast.error("Please enter both email and password.");
      setLoading(false);
      return;
    }

    try {
      const { data } = await axiosInstance.post("/user/login", { email, password });

      if (data.status) {
        sessionStorage.setItem("user", JSON.stringify(data));
        localStorage.setItem("token", data.token);
        window.location.href = "/";
        toast.success("Login Successfully!");
      } else {
        toast.error("Invalid email or password.");
      }
    } catch (error) {
      if (error.response?.status === 401) {
        toast.error("Invalid email or password.");
      } else {
        toast.error("Login failed. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  // password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <section className="bg-white">
      <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
        <section className="relative flex h-32 items-end bg-gray-900 lg:col-span-5 lg:h-full xl:col-span-6">
          <img
            alt="loginImg"
            src={loginImg}
            className="absolute inset-0 h-full w-full object-cover opacity-80"
          />
        </section>

        <main className="px-8 flex flex-col justify-center py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
          <div className="text-center mb-5">
            <h1 className="text-3xl text-blue-950 font-bold">Welcome Back</h1>
          </div>
          <div className="max-w-xl lg:max-w-3xl">
            <form className="space-y-4 md:space-y-6" onSubmit={handleLogin}>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-gray-50 border relative border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1.5"
                  placeholder="name@company.com"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="bg-gray-50 border relative border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1.5"
                    required
                  />
                  {showPassword ? (
                    <FaEye
                      onClick={togglePasswordVisibility}
                      className="absolute cursor-pointer z-50 text-2xl top-2 right-3"
                    />
                  ) : (
                    <FaEyeSlash
                      onClick={togglePasswordVisibility}
                      className="absolute cursor-pointer z-50 text-2xl top-2 right-3"
                    />
                  )}
                </div>
              </div>
              <button
                type="submit"
                disabled={loading}
                className={
                  loading
                    ? "bg-gray-500 text-white px-3 py-1 rounded-md hover:scale-100"
                    : "bg-blue-950 text-white px-3 py-1 rounded-md" 
                }
              >
                {loading ? "Loading..." : "Login"}
              </button>
              <p className="text-red-500">Note* : Don't share the password. It is confidential.</p>
            </form>
          </div>
        </main>
      </div>
    </section>
  );
};

export default LoginForm;
