import React from "react";

// import homePageMainImage from "../Assets/Images/homePageMainImage.png";
import { Outlet } from "react-router-dom";
import SideBar from "../components/SideBar";

const Layout = () => {
  return (
    <>
      <div className="flex h-[100vh] w-[100%]">
        <SideBar />
        {/* <main className="w-[95%] h-full"> */}
          <Outlet />
        {/* </main> */}
      </div>
    </>
  );
};

export default Layout;
