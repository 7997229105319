import React, { useEffect, useState } from "react";
import SalarySlipModal from "../components/SalarySlipModal";

const SalarySlip = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [daysInMonth, setDaysInMonth] = useState(0);
  const [payslipData, setPayslipData] = useState({
    employeeName: "",
    payPeriod: "",
    payLoss: 0,
    lossOfPayDays: 0,
    paidDays: 0,
    payDate: "",
    employeeId: 0,
    basicSalary: 0,
    hra: 0,
    medical: 0,
    attendanceAllowance: 0,
    transportAllowance: 0,
    pli: 0,
    incomeTax: 0,
    providentFund: 0,
    grossSalary: 0,
    netSalary: 0,
    totalDeduction: 0,
  });

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPayslipData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Effect to update daysInMonth when payPeriod changes
  useEffect(() => {
    if (payslipData.payPeriod) {
      const [year, month] = payslipData.payPeriod.split("-").map(Number);
      const totalDaysInMonth = new Date(year, month, 0).getDate();
      setDaysInMonth(totalDaysInMonth);
    }
  }, [payslipData.payPeriod]);

  // Effect to calculate paidDays when daysInMonth or lossOfPayDays changes
  useEffect(() => {
    if (daysInMonth > 0) {
      const paidDays = daysInMonth - Number(payslipData.lossOfPayDays || 0);
      setPayslipData((prevData) => ({
        ...prevData,
        paidDays: paidDays,
      }));
    }
  }, [daysInMonth, payslipData.lossOfPayDays]);

  // Handler to calculate gross and net salary
  const calculateSalaries = (data) => {
    const grossSalary =
      Number(data.basicSalary) +
      Number(data.hra) +
      Number(data.medical) +
      Number(data.attendanceAllowance) +
      Number(data.transportAllowance) +
      Number(data.pli);

    const payLoss = (data.lossOfPayDays * grossSalary) / daysInMonth;

    const roundOfPayLoss = Math.round(payLoss / 10) * 10;

    const totalDeduction =
      Number(data.providentFund) +
      Number(roundOfPayLoss) +
      Number(data.incomeTax);

    const roundOfTotalDeduction = Math.round(totalDeduction / 10) * 10;

    const netSalary = grossSalary - roundOfTotalDeduction;

    // Extract year and month from payPeriod
    const [year, month] = data.payPeriod.split("-");

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthInWords = monthNames[parseInt(month, 10) - 1]; // Convert month to index

    // Format the date to "Month, yyyy"
    const formattedPayPeriod = `${monthInWords}, ${year}`;

    setPayslipData((prevData) => ({
      ...prevData,
      grossSalary,
      netSalary,
      totalDeduction: roundOfTotalDeduction,
      formattedPayPeriod,
      payLoss: roundOfPayLoss,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    calculateSalaries(payslipData);
    toggleModal();
  };

  return (
    <>
      <div className="flex flex-col w-full h-[100vh] overflow-x-hidden">
        <div className="bg-blue-800 flex items-center justify-center text-white font-bold text-center text-2xl py-4">
          <h1 className="w-fit">Generate Pay Slip</h1>
        </div>
        <div className="">
          <form className="bg-gray-100 p-5 rounded-lg" onSubmit={handleSubmit}>
            {/* Employee Pay Summary Section */}
            <section>
              <div className="">
                <h1 className="text-xl font-bold">
                  Employee Pay Summary <span className="text-red-600">*</span>
                </h1>
              </div>
              <div className="flex gap-[50px] mt-6 bg-white p-5 rounded-lg">
                {/* left */}
                <div className="w-1/2">
                  <div className="flex justify-between items-center mb-5">
                    <label>Employee Name</label>
                    <input
                      type="text"
                      required
                      name="employeeName"
                      value={payslipData.employeeName}
                      onChange={handleInputChange}
                      className="border-2 p-2 bg-white rounded-lg ps-2 w-[200px]"
                      placeholder="Employee Name"
                    />
                  </div>
                  <div className="flex justify-between items-center mb-5">
                    <label>Pay Period</label>
                    <input
                      type="month"
                      name="payPeriod"
                      required
                      value={payslipData.payPeriod}
                      onChange={handleInputChange}
                      className="border-2 p-2 bg-white rounded-lg ps-2 w-[200px]"
                      placeholder="Pay Period"
                    />
                  </div>
                  <div className="flex justify-between items-center">
                    <label>Loss of Pay Days</label>
                    <input
                      className="border-2 p-2 bg-white rounded-lg ps-2 w-[200px]"
                      type="number"
                      value={payslipData.lossOfPayDays}
                      onChange={handleInputChange}
                      name="lossOfPayDays"
                      placeholder="Loss of Pay Days"
                    />
                  </div>
                </div>
                {/* right */}
                <div className="w-1/2">
                  <div className="flex justify-between items-center mb-5">
                    <label>Employee ID</label>
                    <input
                      type="text"
                      required
                      name="employeeId"
                      value={payslipData.employeeId}
                      onChange={handleInputChange}
                      className="border-2 p-2 bg-white rounded-lg ps-2 w-[200px]"
                      placeholder="Employee ID"
                    />
                  </div>
                  <div className="flex justify-between items-center mb-5">
                    <label>Paid Days</label>
                    <input
                      type="number"
                      name="paidDays"
                      value={payslipData.paidDays}
                      onChange={handleInputChange}
                      className="border-2 p-2 bg-white rounded-lg ps-2 w-[200px]"
                      placeholder="Paid Days"
                    />
                  </div>
                  <div className="flex justify-between items-center">
                    <label>Pay Date</label>
                    <input
                      type="date"
                      name="payDate"
                      value={payslipData.payDate}
                      onChange={handleInputChange}
                      className="border-2 p-2 bg-white rounded-lg ps-2 w-[200px]"
                      placeholder="Pay Date"
                    />
                  </div>
                </div>
              </div>
            </section>
            {/* Income Details Section */}
            <section className="mt-[50px]">
              <div className="">
                <h1 className="text-xl font-bold">
                  Income Details <span className="text-red-600">*</span>{" "}
                </h1>
              </div>
              <div className="flex gap-[50px] mt-[20px] p-5 rounded-lg bg-white">
                {/* left */}
                <div className="w-1/2">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="font-semibold text-lg">Earnings</h2>
                    <h2 className="font-semibold text-lg">Amount</h2>
                  </div>
                  <hr />
                  <div className="flex justify-between items-center mb-5 mt-4">
                    <label>Basic</label>
                    <input
                      type="number"
                      name="basicSalary"
                      value={payslipData.basicSalary}
                      onChange={handleInputChange}
                      className="border-2 p-2 bg-slate-100 rounded-lg ps-2 w-[200px]"
                    />
                  </div>
                  <div className="flex justify-between items-center mb-5">
                    <label>House Rent Allowance</label>
                    <input
                      type="number"
                      name="hra"
                      value={payslipData.hra}
                      onChange={handleInputChange}
                      className="border-2 p-2 bg-slate-100 rounded-lg ps-2 w-[200px]"
                    />
                  </div>
                  <div className="flex justify-between items-center mb-5">
                    <label>Medical Allowance</label>
                    <input
                      className="border-2 p-2 bg-slate-100 rounded-lg ps-2 w-[200px]"
                      type="number"
                      name="medical"
                      value={payslipData.medical}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex justify-between items-center mb-5">
                    <label>Attendance Allowance</label>
                    <input
                      className="border-2 p-2 bg-slate-100 rounded-lg ps-2 w-[200px]"
                      type="number"
                      name="attendanceAllowance"
                      value={payslipData.attendanceAllowance}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex justify-between items-center mb-5">
                    <label>Transport Allowance</label>
                    <input
                      className="border-2 p-2 bg-slate-100 rounded-lg ps-2 w-[200px]"
                      type="number"
                      name="transportAllowance"
                      value={payslipData.transportAllowance}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex justify-between items-center">
                    <label>PLI</label>
                    <input
                      className="border-2 p-2 bg-slate-100 rounded-lg ps-2 w-[200px]"
                      type="number"
                      name="pli"
                      value={payslipData.pli}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                {/* right */}
                <div className="w-1/2">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="font-semibold text-lg">Deductions</h2>
                    <h2 className="font-semibold text-lg">Amount</h2>
                  </div>
                  <hr />
                  <div className="flex justify-between items-center mb-5 mt-4">
                    <label>Income Tax</label>
                    <input
                      type="number"
                      name="incomeTax"
                      value={payslipData.incomeTax}
                      onChange={handleInputChange}
                      className="border-2 p-2 bg-slate-100 rounded-lg ps-2 w-[200px]"
                    />
                  </div>
                  <div className="flex justify-between items-center mb-5">
                    <label>Provident Fund</label>
                    <input
                      type="number"
                      name="providentFund"
                      value={payslipData.providentFund}
                      onChange={handleInputChange}
                      className="border-2 p-2 bg-slate-100 rounded-lg ps-2 w-[200px]"
                    />
                  </div>
                </div>
              </div>
            </section>
            <div className="flex justify-center mt-[10px]">
              <button type="submit" className="text-white">
                View
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Salary Slip Modal */}
      <SalarySlipModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        payslipData={payslipData}
      />
    </>
  );
};

export default SalarySlip;
