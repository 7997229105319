import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoClose, IoExit, IoMenu } from "react-icons/io5";
import { sidebarRoutes } from "../sidebarRoutes/SidebarRoutes";

function SideBar() {
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);

  const handleLogout = () => {
    sessionStorage.removeItem("user");
    localStorage.removeItem("token");
    window.location.href = "/login";
  };

  const getUser = JSON.parse(sessionStorage.getItem("user"));
  const userRole = getUser.obj.role;
  const menuItems = sidebarRoutes[userRole] || [];

  return (
    <div
      className={
        openMenu
          ? "flex h-[100vh] w-[22%] duration-200 flex-col justify-between border-e bg-[#05314B] overflow-hidden"
          : "flex relative h-[100vh] w-[6%] duration-200 flex-col justify-between border-e bg-[#05314B] overflow-hidden"
      }
    >
      <div className="px-4 py-6">
        <i
          onClick={() => setOpenMenu(!openMenu)}
          className="text-2xl text-white font-bold cursor-pointer"
        >
          {openMenu ? <IoClose /> : <IoMenu />}
        </i>
        <ul className="mt-6 space-y-1">
          {menuItems.map((item) => (
            <li key={item.path} className="relative group">
              <Link
                to={item.path}
                className={`block rounded-lg hover:text-blue-950 hover:bg-[#0FCFFF] px-4 py-2 my-2 text-md font-medium ${
                  location.pathname === item.path
                    ? "text-blue-950 bg-[#0FCFFF]"
                    : "text-white border border-[#0FCFFF]"
                }`}
              >
                <span className="flex items-center gap-2">
                  {item.icon}
                  {openMenu && <span>{item.label}</span>}
                </span>
              </Link>
              {!openMenu && (
                <span className="absolute left-full ml-2 w-auto min-w-max p-2 rounded-md bg-gray-800 text-white text-xs font-medium opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {item.label}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="sticky inset-x-0 bottom-6 border-t border-#0FCFFF">
        <Link
          to="/login"
          onClick={handleLogout}
          className={`block rounded-lg px-4 py-2 m-4 text-md font-medium hover:bg-red-500 hover:text-white text-red-300 ${
            location.pathname === "/login"
              ? "bg-[#0FCFFF]"
              : "border border-red-500"
          }`}
        >
          <span className="flex items-center gap-2">
            <IoExit />
            {openMenu && <span>Logout</span>}
          </span>
        </Link>
      </div>
    </div>
  );
}

export default SideBar;
