import { Navigate } from "react-router-dom";

const ProtectedRoute = ({children}) => {
  const userExist = sessionStorage.getItem("user");

  if (!userExist) {
    return <Navigate to="/login" replace={true} />;
  }
  
  return children;
};

export default ProtectedRoute;
