import React from "react";
import { GiSplitCross } from "react-icons/gi";

const ViewInstallMentsModel = ({ isOpen, handleClose, installments }) => {
  console.log(installments);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div>
        {isOpen && (
          <div className="fixed inset-0 px-2 z-10 overflow-hidden flex items-center justify-center">
            <div
              className={`absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity ${
                isOpen ? "opacity-100" : "opacity-0"
              }`}
              style={{ transitionDuration: "300ms" }}
            ></div>
            <div
              className={`bg-white rounded-md shadow-xl w-[400px] overflow-hidden mx-10 z-50 transform ${
                isOpen ? "scale-100" : "scale-75"
              }`}
              style={{
                transitionDuration: "300ms",
                transitionProperty: "transform, opacity",
              }}
            >
              <div className="bg-indigo-500 text-white px-4 py-2 flex justify-between">
                <h2 className="text-lg font-semibold">Check Installments</h2>
                <GiSplitCross
                  onClick={handleClose}
                  className="h-10 w-10 p-2 bg-red-500 text-white rounded-md cursor-pointer sm:w-auto"
                />
              </div>

              {/* Stepper for Installments */}
              <div className="p-4">
                {installments && installments.length > 0 ? (
                  installments.map((installment, index) => (
                    <div key={index} className="flex items-center mb-4">
                      <div className="w-10 h-10 flex items-center justify-center rounded-full border-2 border-indigo-500 text-indigo-500 font-bold">
                        {index + 1}
                      </div>
                      <div className="ml-4 flex-grow">
                        <div className="text-lg underline font-semibold">{`Installment ${
                          index + 1
                        }`}</div>
                        <div>{`Date: ${formatDate(installment.date)}`}</div>
                        <div>{`Amount: ${installment.amount}`}</div>
                      </div>
                      <div
                        className={`flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full border-l-4 border-r-2 border-gray-300`}
                      >
                        <div
                          className={`w-2 h-2 rounded-full bg-green-500`}
                        ></div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No installments available.</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewInstallMentsModel;
