import React, { useEffect, useState } from "react";
import { GiSplitCross } from "react-icons/gi";
import axiosInstance from "../axiosInstance/axiosInstance";
import toast from "react-hot-toast";

const AddFeeModel = ({ isOpen, handleClose, studentData, setStudentData }) => {
  const [courseFee, setCourseFee] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (studentData) {
      setCourseFee(studentData.courseFee || ""); // Initialize course fee from student data
    }
  }, [studentData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.put(
        `/admission/coursefee/${studentData._id}`,
        { courseFee }
      );

      setStudentData(data);

      handleClose();
      toast.success("Student data updated successfully");
    } catch (error) {
      console.log(error);
      toast.error("Failed to update student data");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div>
        {isOpen && (
          <div className="fixed inset-0 px-2 z-10 overflow-hidden flex items-center justify-center">
            <div
              className={`absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity ${
                isOpen ? "opacity-100" : "opacity-0"
              }`}
              style={{ transitionDuration: "300ms" }}
            ></div>
            <div
              className={`bg-white rounded-md shadow-xl w-[400px] overflow-hidden mx-10 z-50 transform ${
                isOpen ? "scale-100" : "scale-75"
              }`}
              style={{
                transitionDuration: "300ms",
                transitionProperty: "transform, opacity",
              }}
            >
              <div className="bg-indigo-500 text-white px-4 py-2 flex justify-between">
                <h2 className="text-lg font-semibold">Add Course Fee</h2>
                <GiSplitCross
                  onClick={handleClose}
                  className="h-10 w-10 p-2 bg-red-500 text-white rounded-md cursor-pointer sm:w-auto"
                />
              </div>
              <form className="p-4" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="course_fee"
                    className="block text-gray-700 text-xl"
                  >
                    Course Fee
                  </label>
                  <input
                    type="number"
                    name="courseFee"
                    id="courseFee"
                    onChange={(e) => setCourseFee(e.target.value)}
                    placeholder="Course Fee"
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="mt-4 flex justify-end">
                  <button
                    type="submit"
                    className={`bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 ${
                      isLoading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={isLoading}
                  >
                    {isLoading ? "Saving..." : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddFeeModel;
