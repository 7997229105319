import React, { useEffect, useState } from "react";
import { GiSplitCross } from "react-icons/gi";
import axiosInstance from "../axiosInstance/axiosInstance";
import toast from "react-hot-toast";

const UpdateStudentProfileModel = ({
  isOpen,
  handleClose,
  studentData,
  setStudentData,
}) => {
  const [formData, setFormData] = useState();

  // console.log(formData);
  // console.log(studentData);
  // console.log(studentData._id);

  useEffect(() => {
    setFormData(studentData);
  }, [studentData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.put(
        `/admission/update/${studentData._id}`,
        formData
      );
      // console.log(data);
      
      setStudentData(data);
      // console.log(data);
      
      handleClose();
      toast.success("Student data updated successfully");
      // handleClose();
    } catch (error) {
      console.log(error);
      toast.error("Failed to update student data");
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div>
        {isOpen && (
          <div className="fixed inset-0 px-2 z-10 overflow-hidden flex items-center justify-center">
            <div
              className={`absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity ${
                isOpen ? "opacity-100" : "opacity-0"
              }`}
              style={{ transitionDuration: "300ms" }}
            ></div>
            <div
              className={`bg-white rounded-md shadow-xl overflow-hidden w-full mx-10 z-50 transform ${
                isOpen ? "scale-100" : "scale-75"
              }`}
              style={{
                transitionDuration: "300ms",
                transitionProperty: "transform, opacity",
              }}
            >
              <div className="bg-indigo-500 text-white px-4 py-2 flex justify-between">
                <h2 className="text-lg font-semibold">
                  Update Student Profile
                </h2>
                <GiSplitCross
                  onClick={handleClose}
                  className="h-10 w-10 p-2 bg-red-500 text-white rounded-md cursor-pointer sm:w-auto"
                />
              </div>
              <form className="p-4" onSubmit={handleSubmit}>
                <div className="grid grid-cols-4 gap-4">
                  <div>
                    <label className="block text-gray-700">Student Name</label>
                    <input
                      type="text"
                      name="studentName"
                      value={formData.studentName}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Father's Name</label>
                    <input
                      type="text"
                      name="fatherName"
                      value={formData.fatherName}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Mother's Name</label>
                    <input
                      type="text"
                      name="motherName"
                      value={formData.motherName}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Guardian No.</label>
                    <input
                      type="text"
                      name="guardianNumber"
                      value={formData.guardianNumber}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Gender</label>
                    <select
                      name="gender"
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                      value={formData.gender}
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-gray-700">Contact No</label>
                    <input
                      type="text"
                      name="number"
                      value={formData.number}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Date of Birth</label>
                    <input
                      type="date"
                      name="dob"
                      value={formData.dob}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Aadhar No.</label>
                    <input
                      type="text"
                      name="dob"
                      value={formData.aadharNumber}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">City</label>
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">State</label>
                    <input
                      type="text"
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">
                    Permanent Address</label>
                    <input
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Degree</label>
                    <input
                      type="text"
                      name="qualification"
                      value={formData.degree}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">College/University Name</label>
                    <input
                      type="text"
                      name="collegeName"
                      value={formData.collegeName}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Passing Year</label>
                    <input
                      type="text"
                      name="passingYear"
                      value={formData.passingYear}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Grade</label>
                    <input
                      type="text"
                      name="grade"
                      value={formData.grade}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Course Name</label>
                    <input
                      type="text"
                      name="courseName"
                      value={formData.courseName}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Batch Timing</label>
                    <input
                      type="text"
                      name="batchTiming"
                      value={formData.batchTiming}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">
                    Faculty Name
                    </label>
                    <input
                      type="text"
                      name="facultyName"
                      value={formData.facultyName}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">
                    Date of Admission
                    </label>
                    <input
                      type="date"
                      name="admissionDate"
                      value={formData.admissionDate}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">
                    Course Duration
                    </label>
                    <input
                      type="text"
                      name="courseDuration"
                      value={formData.duration}
                      onChange={handleChange}
                      className="mt-1 p-2 border rounded w-full"
                    />
                  </div>
                </div>
                <div className="mt-4 flex justify-end">
                  <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateStudentProfileModel;
