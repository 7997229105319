import React from 'react'
import Card from '../components/Card'

function Job() {
  return (
    <>
  <Card/>
    </>
  )
}

export default Job
